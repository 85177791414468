import fetch, { RequestInit } from 'node-fetch';
import { isNonEmptyArray } from './validation-helpers';

export type GqlResponse<ExpectedDataModel> = {
  data: ExpectedDataModel;
  errors: Error[];
};

export const attemptWithRetries = async <ReturnValue>(
  fn: () => any,
  retryLimit: number = 5,
  retriesLeft: number = retryLimit
): Promise<ReturnValue | null> => {
  try {
    return await fn();
  } catch (err) {
    if (retriesLeft > 0) {
      return await attemptWithRetries(fn, retryLimit, retriesLeft - 1);
    } else {
      console.error(
        `Failed to run attempted function after ${retryLimit} attempts.\n`,
        err
      );
      throw err;
    }
  }
};

export const fetchAndParseWithRetry = async <ResultModel>(
  url: string,
  options: RequestInit,
  retries?: number
): Promise<ResultModel> => {
  const handler = async () => {
    // forcing it to be true here
    const response = await fetch(url, options);
    return await response.json();
  };

  const attemptedResult = await attemptWithRetries<ResultModel>(
    handler,
    retries
  );
  if (!attemptedResult) {
    throw new Error(
      `Failed to fetch and parse. Error output should be above this message. ${JSON.stringify(
        { options },
        undefined,
        4
      )}`
    );
  }

  return attemptedResult;
};

export const attemptGqlQuery = async <ExpectedDataModel>(config: {
  headers?: Record<string, string>;
  url: string;
  query: string;
  variables?: Record<string, any>;
}) => {
  const { url, headers, query, variables } = config;

  const body = JSON.stringify({
    variables,
    query,
  });

  const result = await fetchAndParseWithRetry<GqlResponse<ExpectedDataModel>>(
    url,
    {
      method: 'POST',
      headers,
      body,
    }
  );

  if (isNonEmptyArray(result.errors)) {
    throw new Error(
      `Encountered graphql request errors: \n${JSON.stringify(
        result.errors,
        undefined,
        4
      )}`
    );
  }

  return result?.data || null;
};

export const postRequestToJSONAPI = async (
  endpoint: string,
  headers: Record<string, any>,
  body: Record<string, any>
) => {
  const response = await fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
    body: JSON.stringify(body),
  });

  return await response.json();
};

export const CORRELATION_ID_HEADER = 'x-rippling-cid';
export const WWW_SERVICE_NAMESPACE = 'rippling-www';
